.about-desc {
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: center;
  grid-gap: 1em 4em;
}

.about-desc > span {
  font-weight: 400;
  font-size: 1em;
  text-align: justify;
  max-width: 600px;
}

.about-join {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

.header-image {
  width: 40vw;
}

@media screen and (max-width: 39.9375em) {
  .header-image {
    display: none;
  }

  .about-desc {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .about-desc {
    grid-gap: 1em 2em;
  }

  .header-image {
    width: 60vw;
  }
}