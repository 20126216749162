.agenda-carousel {
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 2em;
}

.agenda-stages {
  display: flex;
  flex-flow: row;
  gap: 1em;
}

.agenda-stages-button {
  background-color: var(--primary);
  font-size: 1.5em;
  padding: 0.75em;
  border: 3px solid var(--primary);
  flex: 1;
}

.agenda-stage {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  border: 3px solid var(--primary);
  padding: 2em;
  gap: 2em;
  height: calc(100% - 5em);
}

.agenda-stage-title > h2 {
  font-weight: 300;
  font-size: 1.5em;
  text-transform: uppercase;
  margin: 0;
}

.agenda-stage-title > h1 {
  font-weight: normal;
  font-size: 2em;
  text-transform: uppercase;
  margin: 0;
}

.agenda-stage-content {
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 2em;
}

.agenda-stage-content > p {
  margin: 0;
  text-align: justify;
}

.agenda-stage-features {
  display: grid;
  grid-template-columns: minmax(100px, 0fr) auto;
  grid-gap: 1em 2em;
}

.agenda-stage-features > h3 {
  font-weight: 700;
  font-size: 1em;
  margin: 0;
}

.agenda-stage-features > h4 {
  font-weight: 500;
  font-size: 1em;
  margin: 0;
  text-align: justify;
}

.agenda-stage-control {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: end;
  gap: 5em;
  margin-top: -2em;
}

.agenda-stages-dots {
  display: flex;
  flex-flow: row;
  gap: 0.5em;
}

.agenda-stages-dot {
  background-color: var(--dark);
  opacity: 0.25;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: none;
}

.agenda-join {
  display: flex;
  flex-flow: row;
  justify-content: center;
}

/* stylelint-disable selector-class-pattern */
.agenda-stages-dot.carousel__dot--selected {
  background-color: var(--primary);
  opacity: 1;
}

.carousel__dot-group {
  display: contents;
}
/* stylelint-enable selector-class-pattern */

.agenda-stages-dots-button {
  border: none;
  background-color: var(--primary);
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .agenda-stage {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }

  .agenda-stage-content > p {
    display: none;
  }

  .agenda-stages {
    display: none;
  }
}

@media screen and (max-width: 39.9375em) {
  .agenda-stage {
    display: flex;
    flex-flow: column;
    padding: 1em;
  }

  .agenda-stage-content > p {
    display: none;
  }

  .agenda-stages {
    display: none;
  }

  .agenda-stage-control {
    gap: 1em;
    justify-content: center;
  }
}