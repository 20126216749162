.benefits-card-container {
  display: grid;
  grid-template-columns: repeat(15, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 1em;
}

.card {
  padding: 1em;
  display: flex;
  flex-flow: column;
  gap: 1em;
  border: 3px solid var(--primary);
}

@media screen and (max-width: 39.9375em) {
  .benefits-container {
    padding: 0 1em;
  }

  .benefits-container > h1 {
    font-size: 1.5em;
    font-weight: normal;
  }

  .card > h1 {
    font-weight: normal;
  }

  .benefits-card-container {
    grid-row-gap: 1em;
  }

  .card {
    padding: 1em;
  }
}

@media screen and (max-width: 63.9375em) {
  .benefits-card-container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}